<template>
  <div class="client-view-talent-assessment">
      <h1>yaaaaaaaaa assesment yaa</h1>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>